import { SSMClient, GetParameterCommand } from "@aws-sdk/client-ssm"

export default class SystemManagerParametersService 
{
  public static async GetParameterValueAsync(apiKey: string, name: string): Promise<string> {
    const client = new SSMClient(
    {
        region: 'eu-north-1',
        credentials: SystemManagerParametersService.GetCredentials(apiKey)
    });

    const input = {
        Name: name,
        WithDecryption: false,
    };
    const command = new GetParameterCommand(input);
    const response = await client.send(command);

    return response.Parameter!.Value!;
  }

  private static GetCredentials(apiKey: string): {accessKeyId: string, secretAccessKey: string}
  {
        var parts = apiKey.split(":");
        return {
            accessKeyId: parts[0],
            secretAccessKey: parts[1]
        }
  }
}
